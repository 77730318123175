<template>
  <Stores/>
</template>

<script>
import Stores from '@/components/Store.vue';

export default {
  name: 'App',
  components: {
    Stores
  }
}
</script>
