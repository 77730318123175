<template>
  <About/>
</template>

<script>
import About from '@/components/About.vue';

export default {
  name: 'App',
  components: {
    About
  }
}
</script>
