<template>
  <Item/>
</template>

<script>
import Item from '@/components/Item.vue';

export default {
  name: 'App',
  components: {
    Item
  }
}
</script>
